import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'convertTime'})
export class ConvertTimePipe implements PipeTransform {
  transform(value: number): string {
    if (value != null) 
    {   
      const totalSeconds = Math.floor(value/1000);
      if (totalSeconds < 60)
      {
          return "00:00:" + this.padTo2Digits(totalSeconds);
      }  
      else if (totalSeconds < 3600)
      {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return "00:" + `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
      } 
      else
      {
        const totalMinutes = Math.floor(totalSeconds / 60);

        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
      }   
    }
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
}